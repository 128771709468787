<template>
  <div class="tile is-parent">
    <article class="tile is-child box detail-page-tile">
      <subtitle-totals :labels="['Total Paid Amount', 'Total With-Hold Tax']"
        :totals="[totalAmount, totalTaxAmount]"
        :shows="[true, true]" />
      <div class="field">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field-body">
              <div class="field has-addons">
                <div class="control has-icons-left has-icons-right">
                  <input class="input"
                    v-model="availableInvoiceQuery"
                    type="text"
                    placeholder="Start typing to filter">
                  <span class="icon is-left">
                    <i class="mdi mdi-magnify mdi-18px" />
                  </span>
                </div>
                <div class="control">
                  <a class="button is-primary is-outlined"
                    @click="reset()"
                    :disabled="availableInvoiceQuery==''">
                    <span class="icon">
                      <i class="mdi mdi-close mdi-18px" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :total-rows="filteredAvailableInvoices.length"
        :is-loading="false"
        :is-title-checkable="true"
        title-checkbox-location="front"
        :show-pagination="false"
        :is-title-checked.sync="isTitleCheck"
        @title-checkbox-changed="titleCheckboxChanged"
        @sort="sort">
        <tr v-for="(invoice, index) in filteredAvailableInvoices"
          :key="invoice.creditorInvoiceId">
          <td class="has-vertical-middle has-text-centered is-content-width">
            <input class="is-checkradio is-box is-primary"
              :class="{ 'has-background-color': invoice.isChecked }"
              :id="`report${index}`"
              :name="`report${index}`"
              :disabled="readOnlyView"
              type="checkbox"
              @change="addPaidAmount(invoice.isChecked, invoice.balance, index)"
              v-model="invoice.isChecked">
            <label :for="`report${index}`"
              data-label-empty />
          </td>
          <td class="has-vertical-middle column-width">{{ invoice.invoiceRef }}</td>
          <td class="has-vertical-middle column-width">{{ $filters.formatDateTimezone(invoice.invoiceDate, $userInfo.locale) }}</td>
          <td class="has-vertical-middle has-text-right column-width">{{ invoice.balance | formatNumber($userInfo.locale) }}</td>
          <td class="has-vertical-middle has-text-right column-width">
            <vue-numeric v-if="invoice.isChecked && isNewEntity"
              class="input has-text-right"
              placeholder="Paid Amount"
              :minus="true"
              :precision="2"
              v-model.number="invoice.paid" />
            <span v-else>{{ invoice.paid | formatNumber($userInfo.locale) }}</span>
          </td>
          <td class="has-vertical-middle column-width">
            <div v-if="!isNewEntity"
              class="control">
              <input class="input is-static"
                :value="$filters.formatDateTimezone(invoice.payDate, $userInfo.locale)"
                readonly>
            </div>
            <div v-else-if="!invoice.isChecked"
              class="control">
              <input class="input is-static"
                :value="$filters.formatDateTimezone(invoice.inputValue, $userInfo.locale)"
                readonly>
            </div>
            <v-date-picker v-else
              v-model="payDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="payDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="control has-icons-left">
                  <input type="text"
                    class="input"
                    placeholder="Paid Date"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly>
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi- 18px" />
                  </span>
                </div>
              </template>
            </v-date-picker>
          </td>
          <td class="has-vertical-middle has-text-right column-width">
            <div class="control">
              <input v-if="invoice.isChecked && isNewEntity"
                class="input"
                placeholder="Cheque No"
                v-model="cheque">
              <span v-else>{{ invoice.cheque }}</span>
            </div>
          </td>
          <td class="has-vertical-middle has-text-right account-width">
            <div v-if="invoice.isChecked && isNewEntity"
              class="control">
              <multiselect v-if="glBankAccountListCombo"
                v-model="glBankAccount"
                placeholder="Select account"
                label="displayName"
                deselect-label="Can't remove this value"
                track-by="accountNo"
                :options="glBankAccountListCombo"
                :loading="isGlBankAccountLoading"
                :allow-empty="false"
                :show-labels="false"
                :disabled="readOnlyView">
                <span class="has-text-danger"
                  slot="noResult">Bank account not found.</span>
              </multiselect>
            </div>
            <div v-if="!invoice.isChecked && isNewEntity"
              class="control">
              <multiselect v-if="glBankAccountListCombo"
                placeholder="Select account"
                label="displayName"
                deselect-label="Can't remove this value"
                track-by="accountNo"
                :options="glBankAccountListCombo"
                :loading="isGlBankAccountLoading"
                :allow-empty="false"
                :show-labels="false"
                :disabled="true">
                <span class="has-text-danger"
                  slot="noResult">Bank account not found.</span>
              </multiselect>
            </div>
            <div v-if="!isNewEntity"
              class="control">
              <multiselect v-if="glBankAccountListCombo"
                v-model="glBankAccount"
                placeholder="Select account"
                label="displayName"
                deselect-label="Can't remove this value"
                track-by="accountNo"
                :options="glBankAccountListCombo"
                :loading="isGlBankAccountLoading"
                :allow-empty="false"
                :show-labels="false"
                :disabled="true">
                <span class="has-text-danger"
                  slot="noResult">Bank account not found.</span>
              </multiselect>
            </div>
          </td>
          <td class="has-vertical-middle column-width">{{ invoice.abn }} </td>
          <td class="has-vertical-middle has-text-right column-width">
            <vue-numeric v-if="invoice.isChecked && isNewEntity"
              class="input has-text-right"
              placeholder="With-Hold Tax"
              v-model.number="invoice.taxWh"
              :minus="true"
              :precision="2" />
            <span v-else>{{ invoice.taxWh | formatNumber($userInfo.locale) }}</span>
          </td>
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </article>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import Multiselect from 'vue-multiselect'
import SubtitleTotals from '@/components/SubtitleTotals'
import VueNumeric from '@/components/VueNumeric'
import { InputValidationDirective } from '@/components/directives'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { EventHubTypes } from '@/enums'
import { PaymentEntryInvoiceColumns } from './columns'
import PaymentEntryService from './PaymentEntryService'

export default {
  inject: ['$vv'],
  components: {
    BulmaTable,
    Multiselect,
    SubtitleTotals,
    VueNumeric
  },
  mixins: [InputValidationDirective, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    isTiled: Boolean,
    readOnlyView: {
      type: Boolean
    },
    isNewEntity: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      glBankAccountListCombo: null,
      isTitleCheck: false,
      payDate: null,
      cheque: null,
      glBankAccount: null,
      availableInvoiceQuery: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    columns() {
      return PaymentEntryInvoiceColumns
    },
    availableInvoices() {
      return this.innerValue.paymentEntryInvoices
    },
    totalAmount() {
      var total = 0
      for (let i = 0; i < this.availableInvoices.length; i++) {
        total = roundAwayFromZero(total + this.availableInvoices[i].paid, 2)
      }
      return total
    },
    totalTaxAmount() {
      var totalTaxWh = 0
      let selectedInvoices = this.filteredAvailableInvoices.filter((i) => i.isChecked === true)
      for (let i = 0; i < selectedInvoices.length; i++) {
        totalTaxWh = roundAwayFromZero(totalTaxWh + selectedInvoices[i].taxWh, 2)
      }
      return totalTaxWh
    },
    filteredAvailableInvoices() {
      const query = this.availableInvoiceQuery.toLowerCase()
      const results = this.availableInvoiceQuery ? this.availableInvoices.filter((i) => i.invoiceRef.toLowerCase().includes(query)) : this.availableInvoices
      return results
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    filteredAvailableInvoices: {
      handler: function (newVal, oldVal) {
        let found = this.filteredAvailableInvoices.some((i) => i.isChecked === false)
        if (this.filteredAvailableInvoices.length > 0 && !found) {
          this.isTitleCheck = true
        } else {
          this.isTitleCheck = false
        }
      },
      deep: true
    },
    payDate: {
      handler: function (newVal, oldVal) {
        this.availableInvoices.forEach(function (invoice) {
          if (invoice.isChecked) {
            if (newVal) {
              invoice.payDate = new Date(newVal).toISOString().split('.')[0] + 'Z'
            }
          }
        })
        let found = this.availableInvoices.some((i) => i.isChecked === true)
        if (found) {
          this.innerValue.payDate = new Date(newVal).toISOString().split('.')[0] + 'Z'
        }
      },
      deep: true
    },
    glBankAccount: {
      handler: function (newVal, oldVal) {
        this.availableInvoices.forEach(function (invoice) {
          if (invoice.isChecked) {
            invoice.bankAccount = newVal.accountNo
            if (newVal.displayName.toLowerCase().includes('batch')) {
              invoice.isBatched = true
            } else {
              invoice.isBatched = false
            }
          }
        })
      },
      deep: true
    },
    cheque: {
      handler: function (newVal, oldVal) {
        this.availableInvoices.forEach(function (invoice) {
          if (invoice.isChecked) {
            invoice.cheque = newVal
          }
        })
      },
      deep: true
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  created() {
    this.GetGlBankAccountsDropdown()
    if (!this.readOnlyView && this.isNewEntity) {
      const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
      this.payDate = utcDate.split('.')[0] + 'Z'
    } else if (!this.isNewEntity) {
      this.isTitleCheck = true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  methods: {
    async GetGlBankAccountsDropdown() {
      this.isGlBankAccountLoading = true
      this.glBankAccountListCombo = await PaymentEntryService.getGlBankAccountsDropdown(this.$userInfo.companyId)
      this.glBankAccount = this.glBankAccountListCombo.find((g) => g.accountNo === this.innerValue.bankAccount)
      this.isGlBankAccountLoading = false
    },
    titleCheckboxChanged(value) {
      if (this.isNewEntity) {
        if (value) {
          const date = new Date(this.payDate)
          this.filteredAvailableInvoices.forEach((i) => (i.isChecked = value))
          this.filteredAvailableInvoices.forEach(function (items) {
            if (items.paid === 0) {
              items.paid = roundAwayFromZero(items.balance - items.taxWh, 2)
            }
          })
          this.filteredAvailableInvoices.forEach((i) => (i.payDate = date.toISOString().split('.')[0] + 'Z'))
          this.filteredAvailableInvoices.forEach((i) => (i.cheque = this.cheque))
          this.filteredAvailableInvoices.forEach((i) => (i.bankAccount = this.glBankAccount.accountNo))

          if (this.glBankAccount.displayName.toLowerCase().includes('batch')) {
            this.filteredAvailableInvoices.forEach((i) => (i.isBatched = true))
          } else {
            this.filteredAvailableInvoices.forEach((i) => (i.isBatched = false))
          }

          this.innerValue.payDate = date.toISOString().split('.')[0] + 'Z'
        } else {
          let filter = this.filteredAvailableInvoices.filter((id) => id.isChecked === true)

          if (filter.length === this.filteredAvailableInvoices.length) {
            this.filteredAvailableInvoices.forEach((i) => (i.isChecked = value))
            this.filteredAvailableInvoices.forEach((i) => (i.paid = 0))
            this.filteredAvailableInvoices.forEach((i) => (i.payDate = null))
            this.filteredAvailableInvoices.forEach((i) => (i.cheque = null))
            this.filteredAvailableInvoices.forEach((i) => (i.bankAccount = null))
            this.filteredAvailableInvoices.forEach((i) => (i.isBatched = false))
            this.innerValue.payDate = null
          }
        }
      }
    },
    addPaidAmount(check, balance, index) {
      if (this.isNewEntity) {
        if (check) {
          const date = new Date(this.payDate)
          this.filteredAvailableInvoices[index].payDate = date.toISOString().split('.')[0] + 'Z'
          this.filteredAvailableInvoices[index].paid = roundAwayFromZero(balance - this.filteredAvailableInvoices[index].taxWh, 2)
          this.filteredAvailableInvoices[index].cheque = this.cheque
          this.filteredAvailableInvoices[index].bankAccount = this.glBankAccount.accountNo
          // this.filteredAvailableInvoices[index].taxWh = 0

          if (this.glBankAccount.displayName.toLowerCase().includes('batch')) {
            this.filteredAvailableInvoices[index].isBatched = true
          } else {
            this.filteredAvailableInvoices[index].isBatched = false
          }

          this.innerValue.payDate = date.toISOString().split('.')[0] + 'Z'
        } else {
          this.filteredAvailableInvoices[index].paid = 0
          this.filteredAvailableInvoices[index].payDate = null
          this.filteredAvailableInvoices[index].cheque = null
          this.filteredAvailableInvoices[index].bankAccount = null
          // this.filteredAvailableInvoices[index].taxWh = 0
          this.filteredAvailableInvoices[index].isBatched = false
          this.innerValue.payDate = null
        }
      }
    },
    reset() {
      this.availableInvoiceQuery = ''
    },
    sort(name, order) {
      this.innerValue.paymentEntryInvoices = _orderBy(this.availableInvoices, name, order)
    }
  }
}
</script>

<style lang="scss" scoped>
.column-width {
  width: 160px;
}
.account-width {
  width: 240px;
}
</style>
