<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="readOnlyView || !isNewEntity"
      :show-delete="false"
      :show-save="isSave && !readOnlyView && isNewEntity"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <payment-entry-side-menu v-if="entity"
          ref="sideMenu"
          :vendor-name="`${entity.vendorName}`" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          :gst-paid="gstPaid"
          :read-only-view="readOnlyView"
          :is-new-entity="isNewEntity" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeUnsavedModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeAllModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmSavePrintModalActive"
      @ok="save()"
      @cancel="print()"
      @close="closeSavePrintModal()"
      :ok-text="'Save & Print'"
      :cancel-text="'Print'"
      :close-text="'Close'"
      :show-close="true">
      <p slot="text-title">Save and Print</p>
      <p slot="text-content">
        Do you want to Save and Print the Remittance Advice?
      </p>
    </confirm-modal>
    <confirm-modal v-if="entity"
      :active.sync="isTaxWhModalActive"
      @ok="ok()"
      @cancel="closeTaxWhModal()"
      :ok-text="'Ok'"
      :cancel-text="'Cancel'">
      <p slot="text-title">With-Hold Tax</p>
      <p slot="text-content">
        You have indicated that With Hold Tax applies to these Payments. Ok to Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import PaymentEntrySideMenu from './PaymentEntrySideMenu'
import PaymentEntryService from './PaymentEntryService'
import PaymentEntryValidation from './PaymentEntryValidation'
import PaymentEntryRoutes from './route-types'
import PaymentListRoutes from '@/views/paymentlist/route-types'
import { Columns } from './columns'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes, EventHubTypes, RemarkTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import StoreUtil from '@/store/utils'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'PaymentEntryView',
  components: {
    AppDetailHeader,
    [PaymentEntrySideMenu.name]: PaymentEntrySideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [PaymentEntryValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isSave: false,
      isNewEntity: false,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isConfirmSavePrintModalActive: false,
      isTaxWhModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    gstPaid() {
      return this.entity.gstPaidDate != null
    },
    columns() {
      return Columns
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
          this.isSave = this.entity.paymentEntryInvoices.some(id => id.isChecked === true)
          // this.$eventHub.$emit(EventHubTypes.EntityReload)
        }
      }, 500),
      deep: true
    }
  },
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.vendorId)
    }
    await this.getEntity()
  },
  mounted() {},
  beforeDestroy() {
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          this.checkIsTaxWhExist()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.vendorId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItemPaymentEntry(this.$route.params.vendorId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
        this.isNewEntity = this.entity.isNew
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    checkIsTaxWhExist() {
      let found = this.entity.paymentEntryInvoices.some(i => i.paid === 0 && i.isChecked === true)
      if (found) {
        this.$notification.openNotificationWithType('danger', 'Payment Entry', 'Paid amount cannot be zero')
        return false
      }

      if (this.entity.paymentEntryInvoices.some(i => i.isChecked == true && i.taxWh != 0)) {
        this.isTaxWhModalActive = true
      } else {
        this.isConfirmSavePrintModalActive = true
      }
    },
    ok() {
      this.isTaxWhModalActive = false
      this.isConfirmSavePrintModalActive = true
    },
    closeTaxWhModal() {
      this.isTaxWhModalActive = false
    },
    closeSavePrintModal() {
      this.isConfirmSavePrintModalActive = false
    },
    closeUnsavedModal() {
      this.isUnsavedModalActive = false
    },
    async save() {
      const title = 'Payment Entry'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      } else if (!this.entity.paymentEntryInvoices.some(i => i.isChecked === true)) {
        this.$notification.openNotificationWithType('danger', title, 'Please select invoice for payment')
        return false
      } else if (this.entity.payDate < this.entity.glCloseDate) {
        this.$notification.openNotificationWithType('danger', title, 'Payment dates cannot be in a closed Accounting Year.')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await PaymentEntryService.postEntityPaymentEntry(this.entity)
        }
        this.$hideSpinner()
        if (response && response.status === HttpStatus.CREATED) {
          await this.print(true)
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, 'Save successful')
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response && e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
          this.isConfirmSavePrintModalActive = false
          this.isSaveConflictModalActive = true
        } else if (e.response) {
          this.$notification.openMessageXhrError('Orm Batching', e)
        } else {
          this.$notification.openNotificationWithType('danger', 'ORM Batching', e.toString())
        }
        return false
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && this.entity.isNew) {
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.vendorId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          if (!this.entity.isNew) {
            this.$router.push({
              name: PaymentListRoutes.PaymentListListView.name
            })
          } else {
            this.$router.push({
              name: PaymentEntryRoutes.PaymentEntryListView.name
            })
          }
        }
      }
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    async print(reset = false) {
      const title = 'Payment Entry'
      if (!reset) {
        if (this.entity.payDate < this.entity.glCloseDate) {
          this.$notification.openNotificationWithType('danger', title, 'Payment dates cannot be in a closed Accounting Year.')
          return false
        }
      }
      //Parse XML
      const paymentsXml = this.generatePaymentsXml()

      //Set Parametters
      const params = Object.assign({
        REPORTTITLE: 'REMITTANCE ADVICE',
        VENDORID: this.entity.vendorId,
        reportDocId: this.$lzstring.compressToBase64(paymentsXml),
        COMPANYNAME: this.$userInfo.companyName,
        CompanyID: this.$userInfo.companyId
      })

      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.vendorId
      emailer.remark = `Remittance Advice for ${this.entity.vendorName}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Remittance Advice for ${this.entity.vendorName}`
      emailer.reportName = 'AT_RemittanceAdvice'
      this.addEmailer(emailer)
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_RemittanceAdvice' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
      this.isConfirmSavePrintModalActive = false
    },
    generatePaymentsXml() {
      let xmlDoc = document.implementation.createDocument('', '', null)
      let cheque
      let remittanceAdviceListElement = xmlDoc.createElement('AT_RemittanceAdvice')

      for (let i = 0; i < this.entity.paymentEntryInvoices.length; i++) {
        if (this.entity.paymentEntryInvoices[i].isChecked) {
          let remittanceAdviceElement = xmlDoc.createElement('RemittanceAdvice')
          let invNoElement = xmlDoc.createElement('InvNo')
          let invDateElement = xmlDoc.createElement('InvDate')
          let invAmountElement = xmlDoc.createElement('InvAmount')
          let balanceElement = xmlDoc.createElement('Balance')
          let paymentElement = xmlDoc.createElement('Payment')
          let chequeElement = xmlDoc.createElement('ChequeNo')

          if (!this.entity.paymentEntryInvoices[i].cheque) {
            cheque = ''
          } else {
            cheque = this.entity.paymentEntryInvoices[i].cheque
          }

          invNoElement.innerHTML = this.entity.paymentEntryInvoices[i].invoiceRef.escapeXml()
          invDateElement.innerHTML = this.$filters.formatDateTimezone(this.entity.paymentEntryInvoices[i].invoiceDate, this.$userInfo.locale)
            .toString()
            .escapeXml()
          invAmountElement.innerHTML = this.entity.paymentEntryInvoices[i].totalAmount.toString().escapeXml()
          balanceElement.innerHTML = this.entity.paymentEntryInvoices[i].balance.toString().escapeXml()
          paymentElement.innerHTML = this.entity.paymentEntryInvoices[i].paid.toString().escapeXml()
          chequeElement.innerHTML = cheque.escapeXml()

          remittanceAdviceElement.appendChild(invNoElement)
          remittanceAdviceElement.appendChild(invDateElement)
          remittanceAdviceElement.appendChild(invAmountElement)
          remittanceAdviceElement.appendChild(balanceElement)
          remittanceAdviceElement.appendChild(paymentElement)
          remittanceAdviceElement.appendChild(chequeElement)
          remittanceAdviceListElement.appendChild(remittanceAdviceElement)
        }
      }
      xmlDoc.appendChild(remittanceAdviceListElement)
      return new XMLSerializer().serializeToString(xmlDoc)
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    async closeAllModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.isConfirmSavePrintModalActive = false
      await this.getEntity(true)
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|paymententry|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|paymententry|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|paymententry|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|paymententry|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: PaymentEntryRoutes.PaymentEntryDetail.name,
        params: { vendorId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && this.entity.isNew) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.vendorId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      if (to.name !== PrintPreviewRoutes.PrintPreview.name) {
        this.setReturnRoute({})
      }
      next()
    }
  }
}
</script>
