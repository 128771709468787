import Guid from '@/components/Guid'

/**
 * GUID format validator
 * @param {String} value
 */
const guidValidator = function(value) {
  return Guid.validGuid(value)
}

export { guidValidator }
