// import component from './vue-numeric.vue'
const component = r => require.ensure([], () => r(require('./vue-numeric.vue')), 'components-chunk')

const plugin = {
  install: Vue => {
    Vue.component(component.name, component)
  }
}

component.install = plugin.install

export default component
