import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'INVOICEREF', true, 'desc', 'left'),
  new TableColumn('Creditor Name', 'VENDORNAME', true, 'asc', 'left'),
  new TableColumn('Inv Date', 'INVOICEDATE', true, 'desc', 'left'),
  new TableColumn('Due Date', 'DUE', true, 'asc', 'left'),
  new TableColumn('Balance', 'BALANCE', true, 'asc', 'right'),
  new TableColumn('Inv Incl. GST', 'TOTALAMOUNT', true, 'asc', 'right'),
  new TableColumn('GST', 'GSTAMOUNT', true, 'asc', 'right'),
  new TableColumn('Inv Amount', 'AMOUNT', true, 'asc', 'right'),
  new TableColumn('Quote No.', 'QuoteNoRef', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const PaymentEntryInvoiceColumns = [
  new TableColumn('Invoice No', 'invoiceRef', true, 'asc', 'left'),
  new TableColumn('Invoice Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Balance', 'balance', true, 'asc', 'right'),
  new TableColumn('Paid Amount', 'paid', true, '', 'right'),
  new TableColumn('Paid Date', 'payDate', true, '', 'left'),
  new TableColumn('Cheque No', 'cheque', true, '', 'left'),
  new TableColumn('Bank Account', 'bankAccount', true, '', 'left'),
  new TableColumn('ABN', 'abn', true, 'asc', 'left'),
  new TableColumn('With-Hold Tax', 'taxWh', true, '', 'right')
]

export { Columns, PaymentEntryInvoiceColumns }
