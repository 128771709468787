<template>
  <p class="subtitle">
    <span v-for="(value, index) in totals"
      :key="index">
      <span v-show="shows[index]">
        {{ labels[index] }}: <span :class="[ value >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ value | formatCurrency($userInfo.locale) }}</span>
      </span><span v-show="index < totals.length - 1 && shows[index + 1]">,</span>
    </span>
  </p>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'SubtitleTotals',
  mixins: [NumberFiltersMixin],
  props: {
    labels: {
      type: Array,
      default: () => {
        return []
      }
    },
    totals: {
      type: Array,
      default: () => {
        return []
      }
    },
    shows: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
