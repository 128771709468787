/**
 * ABN format validator
 * Algorithm @ https://abr.business.gov.au/HelpAbnFormat.aspx
 * @param {String, Number} abn
 */
const weighting = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
const magicNumber = 89

const abnValidator = function(abn) {
  if (!abn) {
    return true
  } else if (abn.toString().length > 0 && abn.toString().length < 11) {
    return false
  }
  const abnDigits = abn
    .toString(10)
    .split('')
    .map(Number)
  abnDigits[0] = abnDigits[0] - 1
  const totalWeight = abnDigits.reduce(function(total, digit, index) {
    return total + digit * weighting[index]
  }, 0)
  const remainder = totalWeight % magicNumber
  // console.log(abnDigits, totalWeight, remainder)
  return remainder === 0
}

const abnMinLength = (countryCode) => {
  if (countryCode === 'AU') {
    return 11
  } else {
    return 0
  }
}

export { abnValidator, abnMinLength }
